import React from "react";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  Layout,
  SEO,
  WhyCloudLabsEfficiencyNav,
  WhyCloudLabsNav,
  WhyCloudLabsStartupTables,
} from "components";
import { graphql } from "gatsby";

const WhyCloudLabsStartupPage = ({ data, location }) => {
  const title = "The Most Cost Effective Lab Space";
  const description =
    "Obtain experimental data the fastest and most cost effective way";
  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <WhyCloudLabsEfficiencyNav
          data={data.inPageNav}
          tab={location && location.pathname}
          subsection={location && location.hash}
          kind={"baseline"}
        >
          <WhyCloudLabsStartupTables data={data.inTable.baseline} />
        </WhyCloudLabsEfficiencyNav>
        <WhyCloudLabsEfficiencyNav
          data={data.inPageNav}
          tab={location && location.pathname}
          subsection={location && location.hash}
          kind={"high"}
        >
          <WhyCloudLabsStartupTables data={data.inTable.high} />
        </WhyCloudLabsEfficiencyNav>
        <WhyCloudLabsEfficiencyNav
          data={data.inPageNav}
          tab={location && location.pathname}
          subsection={location && location.hash}
          kind={"ultra"}
        >
          <WhyCloudLabsStartupTables data={data.inTable.ultra} />
        </WhyCloudLabsEfficiencyNav>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabsStartupPage;

export const query = graphql`
  query WhyCloudLabsStartupPage {
    inPageNav: whyCloudLabsJson(tab: { eq: "startup" }) {
      tabRoutes
      useCapacityRow
      nextButton {
        text
        route
      }
    }
    inTable: whyCloudLabsJson(tab: { eq: "startup" }) {
      baseline {
        content {
          imageData {
            title
            src
            alt
          }
          tableData {
            col1
            col2
            col3
            col4
          }
        }
      }
      high {
        content {
          imageData {
            title
            src
          }
          tableData {
            col1
            col2
            col3
            col4
          }
        }
      }
      ultra {
        content {
          imageData {
            title
            src
          }
          tableData {
            col1
            col2
            col3
            col4
          }
        }
      }
    }
  }
`;
